import { FC, useCallback } from 'react'
import dynamic from 'next/dynamic'
import Image from 'next/image'
import Link from 'next/link'
import { Col, Row, Text, Icon } from '@/client/components'
import { useRouterPush } from '@/client/hooks'
import { IHeader, IHeaderSidebarMenu } from '@/client/types'
import { ROUTER_PATH } from '@/client/utils'
import * as S from './styles'
import Search from './Search'
import Login from './Login'
import Cart from './Cart'
import BottomHeader from './BottomHeader'

const Header: FC<IHeader & IHeaderSidebarMenu> = ({
  header: { accessibility, benefit_list, links, logo, calculator, burger, search, menu_navigation, customer_service },
  setIsSideBarOpen,
  isSidebarOpen
}) => {
  const { routerPush, defaultLocale, pathname, query, asPath, push } = useRouterPush()

  const handleChangeLang = useCallback(
    (lang: string) => {
      push({ pathname: pathname, query }, asPath, { locale: lang })
    },
    [pathname, asPath, query, push]
  )

  return (
    <S.Header>
      <S.SkipLink href='#menu'>{accessibility.menu}</S.SkipLink>
      <S.SkipLink href='#main'>{accessibility.main}</S.SkipLink>
      <S.SkipLink href='#footer'>{accessibility.footer}</S.SkipLink>

      <Row>
        <Col xs={12}>
          <S.TopHeader>
            <S.TopHeaderInfos>
              {benefit_list.map(({ benefit }) => (
                <Text key={benefit} size='xs' color='weak' margin='0 1rem 0 0'>
                  <Icon name='checkmark' />
                  {benefit}
                </Text>
              ))}
            </S.TopHeaderInfos>

            <S.TopHeaderList>
              <S.Flag onClick={() => handleChangeLang('es')} title='Español'>
                <Icon name='spanish' />
              </S.Flag>
              <S.Flag onClick={() => handleChangeLang('en')} title='English'>
                <Icon name='english' />
              </S.Flag>

              {links.map(({ link, label }) => (
                <Link key={label} href={link} passHref legacyBehavior>
                  <S.TopHeaderLinks kind='text' size='xs'>
                    {label}
                  </S.TopHeaderLinks>
                </Link>
              ))}
            </S.TopHeaderList>
          </S.TopHeader>
        </Col>
      </Row>

      <S.MainHeader>
        <Row middle='xs'>
          <Col xs={4} sm={5} md={6} xl={2}>
            <Link href={ROUTER_PATH.HOME[defaultLocale]} title={logo.alt} passHref>
              <S.Logo>
                <Image src={logo.url} alt={logo.alt} width={logo.width} height={logo.height} />
              </S.Logo>
            </Link>
          </Col>

          <Col
            xs={12}
            xl={5}
            xlOffset={1}
            orders={[
              {
                mq: 'xs',
                order: 2
              },
              {
                mq: 'xl',
                order: 0
              }
            ]}
          >
            <Search search={search} />
          </Col>

          <Col xs={8} sm={7} md={6} xl={4}>
            <S.MainHeaderRight>
              <Login />
              <Cart />
              <S.HeaderButton kind='warning' onClick={() => routerPush(calculator.link)}>
                {calculator.label}
              </S.HeaderButton>
              <S.MenuButton type='button' onClick={() => setIsSideBarOpen(!isSidebarOpen)} aria-label={burger}>
                <Icon name='menu' size='s6' />
              </S.MenuButton>
            </S.MainHeaderRight>
          </Col>
        </Row>
      </S.MainHeader>
      <BottomHeader menu_navigation={menu_navigation} customer_service={customer_service} />
    </S.Header>
  )
}

export default Header
