import { FC, useState, useCallback, useRef, useMemo } from 'react'
import { Input, Icon, Text, Loader } from '@/client/components'
import { useDebounce, useRouterPush, useSearchPages, useOnClickOutside } from '@/client/hooks'
import { IHeaderSearch } from '@/client/types'
import { ROUTER_PATH } from '@/client/utils'
import * as S from './styles'

const SearchProduct: FC<{ search: IHeaderSearch }> = ({ search }) => {
  const ref = useRef<HTMLDivElement>(null)
  const { routerPush, defaultLocale } = useRouterPush()
  const [searchValue, setSearchValue] = useState<string>('')
  const debouncedValue = useDebounce(searchValue, 400)
  const { data, isFetching } = useSearchPages(debouncedValue)
  const [isClickedOutside, setClickedOutside] = useState(false)
  useOnClickOutside(ref, () => setClickedOutside(true))

  const isOverlayActive = useMemo(() => {
    let active = !!debouncedValue

    if (isClickedOutside === true) {
      active = false
    }

    return active
  }, [debouncedValue, isClickedOutside])

  const handleClick = useCallback(
    (subtype: 'page' | 'products', url: string) => {
      let path: string = ''
      const pageName = url.split('/')

      if (subtype === 'products') {
        path = ROUTER_PATH.PRODUCTS[defaultLocale] + `/` + pageName[4]
      }

      if (subtype === 'page') {
        path = `/${pageName[3]}`
      }

      routerPush(path)
    },
    [routerPush, defaultLocale]
  )

  return (
    <>
      <S.Overlay $active={isOverlayActive} />
      <div ref={ref}>
        <S.Search role='button' onClick={() => setClickedOutside(false)}>
          <S.SearchInput $active={!!debouncedValue}>
            <Input
              isBlock
              id='input-search'
              name='p'
              type='search'
              placeholder={search.placeholder}
              value={searchValue}
              onChange={(e) => setSearchValue(e.currentTarget.value)}
              autoComplete='off'
              rightElement={
                <S.SearchIcon>{isFetching ? <Loader size='small' /> : <Icon name='search' size='s5' />}</S.SearchIcon>
              }
            />
          </S.SearchInput>

          {data && isClickedOutside === false && (
            <S.SearchResult>
              {data?.length > 0 &&
                data.map(({ id, title, subtype, url }) => (
                  <S.SearchItem key={id} role='button' onClick={() => handleClick(subtype, url)}>
                    <S.PageName>{title}</S.PageName>
                  </S.SearchItem>
                ))}

              {data?.length === 0 && (
                <Text align='center' margin='0.5rem 0 0'>
                  {search.no_items_found}
                </Text>
              )}
            </S.SearchResult>
          )}
        </S.Search>
      </div>
    </>
  )
}

export default SearchProduct
