export const BRANCHES = [
  { id: '10', name: 'Graphic Design Agency' },
  { id: '11', name: 'Printer' },
  { id: '12', name: 'Agency' },
  { id: '13', name: 'Books / Publishing' },
  { id: '20', name: 'Drinks' },
  { id: '21', name: 'Wine' },
  { id: '22', name: 'Water' },
  { id: '24', name: 'Beer' },
  { id: '25', name: 'Spirits' },
  { id: '26', name: 'Non-alcoholic drinks' },
  { id: '27', name: 'Coffee' },
  { id: '28', name: 'Tea' },
  { id: '30', name: 'Food / Groceries' },
  { id: '31', name: 'Farmer' },
  { id: '32', name: 'Gastronomie' },
  { id: '33', name: 'Sweets' },
  { id: '34', name: 'Bakery' },
  { id: '35', name: 'Gourmet Food' },
  { id: '36', name: 'Honey' },
  { id: '37', name: 'Organic food' },
  { id: '38', name: 'Dietary Supplements' },
  { id: '39', name: 'Sport nutrition' },
  { id: '40', name: 'Animals' },
  { id: '50', name: 'Chemical' },
  { id: '51', name: 'CBD' },
  { id: '60', name: 'Cosmetic' },
  { id: '61', name: 'Natural Cosmetics' },
  { id: '70', name: 'Tobacco' },
  { id: '80', name: 'Pharmaceutical' },
  { id: '90', name: 'Various' },
  { id: '91', name: 'Subcontractor' },
  { id: '92', name: 'Packaging' },
  { id: '93', name: 'Industry' },
  { id: '94', name: 'Non-Food' },
  { id: '95', name: 'Textiles' },
  { id: '96', name: 'Florist' },
  { id: '97', name: 'Entertainment' },
  { id: '100', name: 'Garden Centres &amp; Nurseries' },
  { id: '120', name: 'Health &amp; Beauty' },
  { id: '130', name: 'Candles &amp; Scents' },
  { id: '150', name: 'Other Sector' }
]
