import React, { FC } from 'react'
import NextLink from 'next/link'
import { Row, Col, Link, Icon } from '@/client/components'
import { IHeaderCustomerService, IHeaderMenu } from '@/client/types'
import { getSizeList } from './helper'
import * as S from './styles'

const BottomHeader: FC<{ menu_navigation: IHeaderMenu[]; customer_service: IHeaderCustomerService }> = ({
  menu_navigation,
  customer_service
}) => (
  <S.BottomHeader>
    <Row>
      <Col xs={12}>
        <S.BottomHeaderList>
          {menu_navigation &&
            menu_navigation?.map(({ menu_title, items }) => (
              <S.BottomHeaderListItem key={menu_title} tabIndex={0}>
                {menu_title}
                <Icon name='chevron-down' size='s2' />
                <S.MenuList size={getSizeList(items)}>
                  {items &&
                    items?.map(({ menu_item_title, menu_item_link, item_is_subtitle }) => (
                      <NextLink key={menu_item_title} href={menu_item_link} passHref legacyBehavior>
                        <Link className={item_is_subtitle ? 'subtitle' : ''}>{menu_item_title}</Link>
                      </NextLink>
                    ))}
                </S.MenuList>
              </S.BottomHeaderListItem>
            ))}

          <S.BottomHeaderListItem>
            <S.CustomerService>
              {customer_service.name}: <span>{customer_service.phone}</span>
            </S.CustomerService>
          </S.BottomHeaderListItem>
        </S.BottomHeaderList>
      </Col>
    </Row>
  </S.BottomHeader>
)

export default BottomHeader
