// menu_title: string
//   items: {
//     menu_item_title: string
//     menu_item_link: string
//     item_is_subtitle?: boolean
//   }[]

export const en_menu = [
  {
    menu_title: 'Product',
    items: [
      {
        menu_item_title: 'All products',
        menu_item_link: '/product',
        item_is_subtitle: true
      },
      {
        menu_item_title: 'In style of',
        menu_item_link: '/product',
        item_is_subtitle: true
      },
      {
        menu_item_title: 'roll labels',
        menu_item_link: '/product'
      },
      {
        menu_item_title: 'Labels on A4 sheets',
        menu_item_link: '/product'
      },
      {
        menu_item_title: 'wet glue labels',
        menu_item_link: '/product'
      },
      {
        menu_item_title: 'blank labels',
        menu_item_link: '/product'
      },
      {
        menu_item_title: 'By Industry',
        menu_item_link: '/category',
        item_is_subtitle: true
      },
      {
        menu_item_title: 'beer labels',
        menu_item_link: '/product'
      },
      {
        menu_item_title: 'chemical labels',
        menu_item_link: '/product'
      },
      {
        menu_item_title: 'beverage labels',
        menu_item_link: '/product'
      },
      {
        menu_item_title: 'spice labels',
        menu_item_link: '/product'
      },
      {
        menu_item_title: 'honey labels',
        menu_item_link: '/product'
      },
      {
        menu_item_title: 'industrial labels',
        menu_item_link: '/product'
      },
      {
        menu_item_title: 'cosmetic labels',
        menu_item_link: '/product'
      },
      {
        menu_item_title: 'food labels',
        menu_item_link: '/product'
      },
      {
        menu_item_title: 'Dietary supplement labels',
        menu_item_link: '/product'
      },
      {
        menu_item_title: 'wine labels',
        menu_item_link: '/product'
      },
      {
        menu_item_title: 'Other industries',
        menu_item_link: '/product'
      },
      {
        menu_item_title: 'By properties',
        menu_item_link: '/product',
        item_is_subtitle: true
      },
      {
        menu_item_title: 'Scratch Resistant Labels',
        menu_item_link: '/product'
      },
      {
        menu_item_title: 'Caustic Soluble Labels',
        menu_item_link: '/product'
      },
      {
        menu_item_title: 'Opaque labels',
        menu_item_link: '/product'
      },
      {
        menu_item_title: 'frozen labels',
        menu_item_link: '/product'
      },
      {
        menu_item_title: 'Transparency',
        menu_item_link: '/product/transparency'
      }
    ]
  },
  {
    menu_title: 'Print data & Design',
    items: [
      {
        menu_item_title: 'print data',
        menu_item_link: '/print-data-delivery'
      },
      {
        menu_item_title: 'design service',
        menu_item_link: '/design-service'
      },
      {
        menu_item_title: 'print data workshop',
        menu_item_link: '/artwork-services'
      },
      {
        menu_item_title: 'Print template help',
        menu_item_link: '/artwork-assistant'
      },
      {
        menu_item_title: 'tutorials',
        menu_item_link: '/tutorials'
      },
      {
        menu_item_title: 'sample book',
        menu_item_link: '/sample-book'
      }
    ]
  },
  {
    menu_title: 'Materials',
    items: [
      {
        menu_item_title: 'papers & foils',
        menu_item_link: '/materials'
      },
      {
        menu_item_title: 'finishing',
        menu_item_link: '/finishing-touches'
      },
      {
        menu_item_title: 'sample book',
        menu_item_link: '/sample-book'
      }
    ]
  },
  {
    menu_title: 'References',
    items: [
      {
        menu_item_title: 'reference customers',
        menu_item_link: '/reference-customers'
      },
      {
        menu_item_title: 'customer stories',
        menu_item_link: '/customer-stories'
      },
      {
        menu_item_title: 'Label Gallery',
        menu_item_link: '/label-gallery'
      }
    ]
  },
  {
    menu_title: 'Information centre',
    items: [
      {
        menu_item_title: 'Organization of all landing pages',
        menu_item_link: '/'
      }
    ]
  },
  {
    menu_title: 'Company',
    items: [
      {
        menu_item_title: 'About us',
        menu_item_link: '/about-us'
      },
      {
        menu_item_title: 'Career',
        menu_item_link: '/jobs'
      },
      {
        menu_item_title: 'News',
        menu_item_link: '/news'
      },
      {
        menu_item_title: 'Press',
        menu_item_link: '/press'
      },
      {
        menu_item_title: 'Newsletter',
        menu_item_link: '/'
      }
    ]
  }
]

export const es_menu = [
  {
    menu_title: 'Producto',
    items: [
      {
        menu_item_title: 'Todos los productos',
        menu_item_link: '/productos',
        item_is_subtitle: true
      },
      {
        menu_item_title: 'Al estilo de',
        menu_item_link: '/productos',
        item_is_subtitle: true
      },
      {
        menu_item_title: 'etiquetas en rollo',
        menu_item_link: '/productos'
      },
      {
        menu_item_title: 'Etiquetas en hojas A4',
        menu_item_link: '/productos'
      },
      {
        menu_item_title: 'etiquetas de pegamento húmedo',
        menu_item_link: '/productos'
      },
      {
        menu_item_title: 'etiquetas en blanco',
        menu_item_link: '/productos'
      },
      {
        menu_item_title: 'Por industria',
        menu_item_link: '/categoría',
        item_is_subtitle: true
      },
      {
        menu_item_title: 'etiquetas de cerveza',
        menu_item_link: '/productos'
      },
      {
        menu_item_title: 'etiquetas químicas',
        menu_item_link: '/productos'
      },
      {
        menu_item_title: 'etiquetas de bebidas',
        menu_item_link: '/productos'
      },
      {
        menu_item_title: 'etiquetas de especias',
        menu_item_link: '/productos'
      },
      {
        menu_item_title: 'etiquetas de miel',
        menu_item_link: '/productos'
      },
      {
        menu_item_title: 'etiquetas industriales',
        menu_item_link: '/productos'
      },
      {
        menu_item_title: 'etiquetas cosméticas',
        menu_item_link: '/productos'
      },
      {
        menu_item_title: 'etiquetas de alimentos',
        menu_item_link: '/productos'
      },
      {
        menu_item_title: 'Etiquetas de suplementos dietéticos',
        menu_item_link: '/productos'
      },
      {
        menu_item_title: 'etiquetas de vino',
        menu_item_link: '/productos'
      },
      {
        menu_item_title: 'Otras industrias',
        menu_item_link: '/productos'
      },
      {
        menu_item_title: 'Por propiedades',
        menu_item_link: '/productos',
        item_is_subtitle: true
      },
      {
        menu_item_title: 'Etiquetas resistentes a los arañazos',
        menu_item_link: '/productos'
      },
      {
        menu_item_title: 'Etiquetas solubles cáusticas',
        menu_item_link: '/productos'
      },
      {
        menu_item_title: 'Etiquetas opacas',
        menu_item_link: '/productos'
      },
      {
        menu_item_title: 'etiquetas congeladas',
        menu_item_link: '/productos'
      },
      {
        menu_item_title: 'Transparencia',
        menu_item_link: '/producto/transparencia'
      }
    ]
  },
  {
    menu_title: 'Imprimir datos y diseño',
    items: [
      {
        menu_item_title: 'Imprimir datos',
        menu_item_link: '/entrega-de-datos-de-impresion'
      },
      {
        menu_item_title: 'Servicio de diseño',
        menu_item_link: '/servicio-de-diseno'
      },
      {
        menu_item_title: 'Taller de impresión de datos',
        menu_item_link: '/servicios-de-arte'
      },
      {
        menu_item_title: 'Imprimir plantilla de ayuda',
        menu_item_link: '/artwork-assistant'
      },
      {
        menu_item_title: 'tutoriales',
        menu_item_link: '/tutoriales'
      },
      {
        menu_item_title: 'Libro de muestra',
        menu_item_link: '/muestrario'
      }
    ]
  },
  {
    menu_title: 'Materiales',
    items: [
      {
        menu_item_title: 'papeles y láminas',
        menu_item_link: '/materiales'
      },
      {
        menu_item_title: 'Toques finales',
        menu_item_link: '/toques-finales'
      },
      {
        menu_item_title: 'libro de muestra',
        menu_item_link: '/muestrario'
      }
    ]
  },
  {
    menu_title: 'Referencias',
    items: [
      {
        menu_item_title: 'Clientes de referencia',
        menu_item_link: '/clientes'
      },
      {
        menu_item_title: 'historias de clientes',
        menu_item_link: '/historias-de-clientes'
      },
      {
        menu_item_title: 'Galería de etiquetas',
        menu_item_link: '/galeria-de-etiquetas'
      }
    ]
  },
  {
    menu_title: 'Centro de información',
    items: [
      {
        menu_item_title: 'Organización de todas las páginas de destino',
        menu_item_link: '/'
      }
    ]
  },
  {
    menu_title: 'Compañía',
    items: [
      {
        menu_item_title: 'Sobre nosotros',
        menu_item_link: '/sobre-nosotros'
      },
      {
        menu_item_title: 'Carrera',
        menu_item_link: '/trabajos'
      },
      {
        menu_item_title: 'Noticias',
        menu_item_link: '/noticias'
      },
      {
        menu_item_title: 'Prensa',
        menu_item_link: '/prensa'
      },
      {
        menu_item_title: 'Boletín',
        menu_item_link: '/'
      }
    ]
  }
]
