import { FC } from 'react'
import { Row, Col, Text, Icon } from '@/client/components'
import { IconName } from '@/client/components/Icon/types'
import { ITopFooter } from '@/client/types'
import * as S from './styles'

export const TopFooter: FC<ITopFooter> = ({ top_footer }) => (
  <S.TopFooter>
    <Row>
      {top_footer.map(({ icon, title }) => (
        <Col xs={12} lg={3} key={title}>
          <S.TopFooterItem>
            <S.TopFooterCircle>
              <Icon name={icon as IconName} size='s7' />
            </S.TopFooterCircle>
            <Text align='center' margin='0'>
              {title}
            </Text>
          </S.TopFooterItem>
        </Col>
      ))}
    </Row>
  </S.TopFooter>
)
