import React, { FC } from 'react'
import Head from 'next/head'

type SEO = {
  title?: string
  description?: string
  copyright?: string
  abstract?: string
  robots?: {
    index?: string
    follow?: string
    'max-snippet'?: string
    'max-image-preview'?: string
    'max-video-preview'?: string
  }
  canonical?: string
  type?: string
  image?: string
}

interface HeadersProps {
  seo?: SEO
}

const Headers: FC<HeadersProps> = ({ seo }) => (
  <Head>
    <meta name='viewport' content='initial-scale=1.0, width=device-width' />
    <title>{seo?.title || 'Etikett'}</title>
    <meta name='description' content={seo?.description || 'Etikett description'} />
    <meta
      name='robots'
      content={
        `${seo?.robots?.index}, ${seo?.robots?.follow}, ${seo?.robots?.['max-snippet']}, ${seo?.robots?.['max-image-preview']}, ${seo?.robots?.['max-video-preview']}` ||
        'index, follow'
      }
    />
    <meta name='copyright' content={seo?.copyright || 'Etikett'} />
    <meta name='abstract' content={seo?.abstract || seo?.description || 'Etikett description'} />

    <meta property='og:url' content={seo?.canonical || 'http://etikett.de'} />
    <meta property='og:type' content={seo?.type || 'website'} />
    <meta property='og:locale' content='pt-BR' />
    <meta property='og:title' content={seo?.title} />
    <meta property='og:description' content={seo?.description} />
    <meta property='og:image' content={seo?.image || 'http://etikett.de/logo.png'} />
    <meta property='og:image:width' content='200' />
    <meta property='og:image:height' content='267' />
    <meta property='fb:admins' content='627008' />
    <meta property='fb:app_id' content='312936488776247' />

    <meta name='twitter:title' content={seo?.title} />
    <meta name='twitter:description' content={seo?.description} />
    <meta name='twitter:image' content={seo?.image || 'http://etikett.de/logo.png'} />
    <meta name='twitter:card' content='summary' />
    <meta name='twitter:creator' content='Etikett' />
  </Head>
)

export default Headers
