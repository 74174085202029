import styled from 'styled-components'
import { theme } from '@/client/components'
const { breakpoint, borders, color, font, space, opacity } = theme

export const Overlay = styled.div<{ $active: boolean }>`
  display: ${(p) => (p.$active ? 'block' : 'none')};
  position: fixed;
  z-index: 99;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.4);
  opacity: 1;
`

export const Search = styled.div`
  position: relative;
  z-index: 99;
`

export const SearchInput = styled.div<{ $active: boolean }>`
  display: none;
  position: relative;
  width: 100%;
  z-index: ${(p) => (p.$active ? 99 : 0)};

  @media (min-width: ${breakpoint.lg}) {
    display: block;
  }
`

export const SearchResult = styled.ul`
  position: absolute;
  top: ${space.xl3};
  left: 0;
  right: 0;
  margin: 0;
  padding: ${space.md} 0;
  background-color: ${color.grayscale.white};
  border-radius: 0 0 ${borders.radius.lg} ${borders.radius.lg};
  list-style: none;
  box-shadow: 0 1.5rem 3rem rgba(0, 0, 0, ${opacity.lv3});
`

export const SearchItem = styled.li`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  padding: ${space.xs} ${space.sm};
  text-align: center;
  cursor: pointer;
  transition: opacity 0.4s ease;

  &:hover {
    background-color: ${color.primary.dark};

    p,
    span {
      color: ${color.grayscale.white};
    }
  }
`

export const PageName = styled.p`
  margin: 0;
  font-size: ${font.size.small};
  font-weight: ${font.weight.medium};
  color: ${color.grayscale.darker};
`

export const ProductImage = styled.div`
  position: relative;

  img {
    display: block;
    width: 100%;
    height: auto;
  }
`

export const SearchIcon = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0 ${space.sm};
  width: ${space.xl2};
  height: ${space.xl2};
`
