import React, { FC } from 'react'
import { Row, Col, Text, Image, Icon } from '@/client/components'
import { IconName } from '@/client/components/Icon/types'
import { IFooterInfos } from '@/client/types'
import * as S from './styles'

export const FooterInfos: FC<IFooterInfos> = ({ footer_infos: { payment, shop_safely, certified, awards } }) => (
  <S.FooterInfos>
    <Row>
      <Col xs={12} lg={3}>
        <S.FooterInfosItem>
          <Text size='lg' family='heading' weight='bold'>
            {shop_safely.title}
          </Text>

          {/*  @ts-ignore */}
          <etrusted-widget data-etrusted-widget-id='wdg-487ea5b9-83b7-4245-b755-fc9d78fa84bf'></etrusted-widget>
        </S.FooterInfosItem>
      </Col>
      <Col xs={12} lg={3}>
        <S.FooterInfosItem>
          <Text size='lg' family='heading' weight='bold'>
            {payment.title}
          </Text>

          <S.PaymentList>
            {payment.credit_cards.map(({ name, icon }) => (
              <S.PaymentListItem key={name} title={name}>
                {icon ? <Icon name={icon as IconName} size={icon !== 'paypal' ? 's3' : 's1'} /> : name}
              </S.PaymentListItem>
            ))}
          </S.PaymentList>
        </S.FooterInfosItem>
      </Col>

      <Col xs={12} lg={3}>
        <S.FooterInfosItem>
          <Text size='lg' family='heading' weight='bold'>
            {certified.title}
          </Text>
          <S.HorizontalList>
            {certified.certifications.map(({ image: { url, alt, width, height } }, index) => (
              <S.WrapImage key={url} width={index === 1 ? '120' : '60'}>
                <Image
                  src={url}
                  alt={alt}
                  width={width}
                  height={height}
                  style={{ display: 'block', width: '100%', height: 'auto' }}
                />
              </S.WrapImage>
            ))}
          </S.HorizontalList>
        </S.FooterInfosItem>
      </Col>
      <Col xs={12} lg={3}>
        <S.FooterInfosItem>
          <Text size='lg' family='heading' weight='bold'>
            {awards.title}
          </Text>

          <S.HorizontalList>
            {awards.list.map(({ image: { url, alt, width, height } }) => (
              <S.WrapImage key={url} width='110'>
                <Image
                  src={url}
                  alt={alt}
                  width={width}
                  height={height}
                  style={{ display: 'block', width: '100%', height: 'auto' }}
                />
              </S.WrapImage>
            ))}
          </S.HorizontalList>
        </S.FooterInfosItem>
      </Col>
    </Row>
  </S.FooterInfos>
)
