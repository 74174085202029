import { FC, useCallback } from 'react'
import Image from 'next/image'
import Link from 'next/link'
import { Col, Collapse, CollapseItem, Row, Text, Icon } from '@/client/components'
import { IconName } from '@/client/components/Icon/types'
import { useRouterPush, useWindowSize } from '@/client/hooks'
import { IBottomFooter } from '@/client/types'
import { ROUTER_PATH } from '@/client/utils'
import * as S from './styles'

export const BottomFooter: FC<IBottomFooter> = ({ bottom_footer: { footer_links, left_side, copyright } }) => {
  const windowSize = useWindowSize()
  const { defaultLocale } = useRouterPush()
  const isLgBreakpoint = windowSize?.width > 768

  const FooterLink = useCallback(({ title, link, is_external_link }) => {
    if (is_external_link) {
      return (
        <S.LinkFooter kind='text' href={link} isBlank={is_external_link}>
          {title}
        </S.LinkFooter>
      )
    }

    return (
      // <Link href={link} passHref legacyBehavior>
      <S.LinkFooter kind='text' href={link}>
        {title}
      </S.LinkFooter>
      // </Link>
    )
  }, [])

  return (
    <S.BottomFooter>
      {!isLgBreakpoint && (
        <S.CollapseWrapper>
          <Row>
            <Col>
              <Collapse>
                {footer_links.map(({ title, links }) => (
                  <CollapseItem key={'CollapseItem' + title} title={title}>
                    <S.LinkList>
                      {links.length > 0 &&
                        links.map(({ title, link, is_external_link }, index) => (
                          <FooterLink
                            key={'FooterLink' + link + index}
                            title={title}
                            link={link}
                            is_external_link={is_external_link}
                          />
                        ))}
                    </S.LinkList>
                  </CollapseItem>
                ))}
              </Collapse>
            </Col>
          </Row>
        </S.CollapseWrapper>
      )}

      <Row>
        <Col xs={12} lg={3}>
          <Link href={ROUTER_PATH.HOME[defaultLocale]} passHref title='Logo Etikett.de'>
            <S.Logo>
              <Image
                src={left_side.logo.url}
                alt={left_side.logo.alt}
                width={left_side.logo.width}
                height={left_side.logo.height}
              />
            </S.Logo>
          </Link>
          <Text size='sm' color='weak'>
            {left_side.description}
          </Text>
          <Text size='sm' weight='medium'>
            <span dangerouslySetInnerHTML={{ __html: left_side.address }} />
          </Text>
          <Text size='sm' weight='medium' margin='0 0 1.5rem'>
            {left_side.phone} <br />
            {left_side.email}
          </Text>

          <S.NavSocialLink>
            {left_side.social_media.map(({ name, type, link }) => (
              <S.SocialLink
                key={name}
                type={type}
                href={link}
                target='_blank'
                rel='noopener noreferrer'
                aria-label={name}
              >
                <Icon name={type as IconName} size='s4' />
              </S.SocialLink>
            ))}
          </S.NavSocialLink>
        </Col>

        {isLgBreakpoint && (
          <Col xs={12} lg={9} xl={8} xlOffset={1}>
            <Row>
              {footer_links.map(({ title, links }) => (
                <Col key={title} xs={12} lg={4}>
                  <Text size='lg' family='heading' weight='bold'>
                    {title}
                  </Text>
                  <S.LinkList>
                    {links.length > 0 &&
                      links.map(({ title, link, is_external_link }) => (
                        <FooterLink
                          key={'merda' + title}
                          title={title}
                          link={link}
                          is_external_link={is_external_link}
                        />
                      ))}
                  </S.LinkList>
                </Col>
              ))}
            </Row>
          </Col>
        )}
      </Row>
      <Row>
        <Col>
          <Text size='xs' color='weak' margin='0'>
            {copyright}
          </Text>
        </Col>
      </Row>
      <div style={{ display: 'none' }}>Casa da etiqueta</div>
    </S.BottomFooter>
  )
}
