import { en_menu } from './menu'

export const header = {
  accessibility: {
    menu: 'Go to menu',
    main: 'Go to main content',
    footer: 'Go to footer'
  },
  benefit_list: [
    {
      benefit: 'Free shipping within the EU'
    },
    {
      benefit: '48 hours express production'
    },
    {
      benefit: 'CO2 reduced production'
    }
  ],
  links: [
    {
      link: '/contact',
      label: 'Contact'
    },
    {
      link: '/customer-service-faq',
      label: 'FAQ'
    }
  ],
  logo: {
    alt: 'Logo label.uk',
    url: '/img/logos/logo-etiqueta-es.png',
    width: 180,
    height: 60
  },
  calculator: {
    label: 'Calculate now',
    link: '/'
  },
  burger: 'Menu',
  search: {
    placeholder: 'Search',
    label: 'Go search page'
  },
  menu_navigation: en_menu,
  customer_service: {
    name: 'Customer Service',
    phone: '06502 - 984 940'
  }
}
