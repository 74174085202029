import { FC } from 'react'
import { IFooter } from '@/client/types'
import { BottomFooter } from './BottomFooter'
import { FooterInfos } from './FooterInfos'
import { Infos } from './Infos'
import { TopFooter } from './TopFooter'

const Footer: FC<IFooter> = ({ footer: { infos, top_footer, footer_infos, bottom_footer } }) => (
  <footer id='footer'>
    <Infos infos={infos} />
    <TopFooter top_footer={top_footer} />
    <FooterInfos footer_infos={footer_infos} />
    <BottomFooter bottom_footer={bottom_footer} />
  </footer>
)

export default Footer
