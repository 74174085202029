import { useCallback, FC } from 'react'
import { Collapse, CollapseItem, Text, Icon } from '@/client/components'
import { IconName } from '@/client/components/Icon/types'
import { ISidebarMenu } from '@/client/types'
import * as S from './styles'

const SidebarMenu: FC<ISidebarMenu> = ({ options, socialLinks, setIsSideBarOpen, isSidebarOpen }) => {
  const handleCloseUserSidebar = useCallback(
    (event) => {
      event.stopPropagation()
      setIsSideBarOpen(false)
    },
    [setIsSideBarOpen]
  )

  return (
    <>
      <S.Background isOpen={isSidebarOpen} onClick={handleCloseUserSidebar} />

      <S.Sidebar isOpen={isSidebarOpen}>
        <Collapse>
          {options &&
            options.map(({ menu_title, items }) => (
              <CollapseItem
                key={'CollapseItem' + menu_title}
                title={
                  <Text size='lg' margin='0'>
                    {menu_title}
                  </Text>
                }
              >
                <S.Options>
                  {items &&
                    items?.map(({ menu_item_title, menu_item_link, item_is_subtitle }) => {
                      if (item_is_subtitle) {
                        return (
                          <Text key={menu_item_title} weight='bold' margin='0 0 0.5rem'>
                            {menu_item_title}
                          </Text>
                        )
                      }

                      return (
                        <S.MenuLink key={menu_item_title} href={menu_item_link}>
                          {menu_item_title}
                        </S.MenuLink>
                      )
                    })}
                </S.Options>
              </CollapseItem>
            ))}
        </Collapse>

        <S.BottomSidebar>
          <S.NavSocialLink>
            {socialLinks.social_media.map(({ name, type, link }) => (
              <S.SocialLink
                key={name}
                type={type}
                href={link}
                target='_blank'
                rel='noopener noreferrer'
                aria-label={name}
              >
                <Icon name={type as IconName} size='s4' />
              </S.SocialLink>
            ))}
          </S.NavSocialLink>

          <Text size='xs' color='weak' margin='0'>
            © Copyright {new Date().getFullYear()} etikett.de - Alle Rechte vorbehalten.
          </Text>
        </S.BottomSidebar>
      </S.Sidebar>
    </>
  )
}

export default SidebarMenu
