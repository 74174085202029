export const ADDRESS = [
  {
    id: '69079',
    company: 'Eric',
    first_name: 'Eric',
    surname: 'Vasconcelos',
    address: 'Rua Namur, 792',
    address2: '',
    address3: '',
    telephone: '61992391847',
    postcode: '21330-600',
    town: 'Rio de Janeiro',
    country_id: '97',
    country: 'Brazil',
    name: 'Eric, (Vasconcelos Eric), Rua Namur, 792 in 21330-600 Rio de Janeiro, Brazil'
  }
]
