export const TITLES = {
  en: [
    {
      id: 'Ms',
      name: 'Ms'
    },
    {
      id: 'Mr',
      name: 'Mr'
    },
    {
      id: 'Not specified',
      name: 'Not specified'
    }
  ],
  es: [
    {
      id: 'Ms',
      name: 'Sra'
    },
    {
      id: 'Mr',
      name: 'Sr'
    },
    {
      id: 'Not specified',
      name: 'Sin especificar'
    }
  ]
}
