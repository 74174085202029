import { useCallback } from 'react'
import { Icon } from '@/client/components'
import { useGetCartItems, useRouterPush } from '@/client/hooks'
import { ROUTER_PATH } from '@/client/utils'
import * as S from './styles'

const Cart = () => {
  const { routerPush, defaultLocale } = useRouterPush()
  const { data, isLoading } = useGetCartItems()

  const handleClickCart = useCallback(
    (e) => {
      e.preventDefault()
      routerPush(ROUTER_PATH.CART[defaultLocale])
    },
    [routerPush, defaultLocale]
  )

  return (
    <S.Cart onClick={handleClickCart}>
      <Icon name='cart' size='s5' />
      {!isLoading && data && <S.CartNumber>{data?.quantity_items}</S.CartNumber>}
    </S.Cart>
  )
}

export default Cart
