export const t = {
  shipping_costs: {
    en: 'Shipping costs',
    es: 'Costos de envío'
  },
  duration: {
    en: 'Duration',
    es: 'Duración'
  },
  working_days: {
    en: 'working days',
    es: 'días laborables'
  },
  within_the_eu: {
    en: 'within the EU',
    es: 'dentro de la UE'
  },
  express_surcharge: {
    en: 'Express surcharge',
    es: 'Recargo exprés'
  },
  approximately: {
    en: 'approx',
    es: 'aproximadamente'
  },
  continue_reading: {
    en: 'Continue reading',
    es: 'Sigue leyendo'
  },
  hello: {
    en: 'Hello',
    es: 'Hola'
  }
}
