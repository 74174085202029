export const COUNTRIES = [
  { id: '90', name: 'Algeria' },
  { id: '62', name: 'Armenia' },
  { id: '37', name: 'Australia' },
  { id: '6', name: 'Austria' },
  { id: '49', name: 'Bailiwick of Jersey' },
  { id: '1', name: 'Belgium' },
  { id: '97', name: 'Brazil' },
  { id: '17', name: 'Bulgaria' },
  { id: '40', name: 'Canada' },
  { id: '75', name: 'Canary Islands' },
  { id: '64', name: 'China' },
  { id: '52', name: 'Colombia' },
  { id: '67', name: 'Costa Rica' },
  { id: '41', name: 'Croatia' },
  { id: '30', name: 'Cyprus' },
  { id: '23', name: 'Czech Republic' },
  { id: '2', name: 'Denmark' },
  { id: '69', name: 'Egypt' },
  { id: '18', name: 'Estonia' },
  { id: '11', name: 'Finland' },
  { id: '3', name: 'France' },
  { id: '56', name: 'French Guiana' },
  { id: '100', name: 'Gabon' },
  { id: '28', name: 'Germany' },
  { id: '108', name: 'Ghana' },
  { id: '27', name: 'Greece' },
  { id: '46', name: 'Greenland' },
  { id: '54', name: 'Guadeloupe' },
  { id: '51', name: 'Guernsey' },
  { id: '26', name: 'Hungary' },
  { id: '35', name: 'Iceland' },
  { id: '89', name: 'India' },
  { id: '103', name: 'Indonesia' },
  { id: '12', name: 'Ireland' },
  { id: '48', name: 'Isle of Man' },
  { id: '79', name: 'Israel' },
  { id: '9', name: 'Italy' },
  { id: '42', name: 'Japan' },
  { id: '70', name: 'Kenya' },
  { id: '19', name: 'Latvia' },
  { id: '82', name: 'Lebanon' },
  { id: '29', name: 'Liechtenstein' },
  { id: '20', name: 'Lithuania' },
  { id: '4', name: 'Luxembourg' },
  { id: '106', name: 'Madagascar' },
  { id: '74', name: 'Maldives' },
  { id: '39', name: 'Malta' },
  { id: '104', name: 'Mexico' },
  { id: '105', name: 'Montenegro' },
  { id: '63', name: 'Morocco' },
  { id: '5', name: 'Netherlands' },
  { id: '53', name: 'New Caledonia' },
  { id: '68', name: 'Nigeria' },
  { id: '13', name: 'Norway' },
  { id: '96', name: 'Panama' },
  { id: '21', name: 'Poland' },
  { id: '14', name: 'Portugal' },
  { id: '58', name: 'Réunion' },
  { id: '22', name: 'Romania' },
  { id: '112', name: 'San Marino' },
  { id: '98', name: 'Saudi Arabia' },
  { id: '65', name: 'Serbia' },
  { id: '24', name: 'Slovak Republic' },
  { id: '25', name: 'Slovenia' },
  { id: '44', name: 'South Africa' },
  { id: '15', name: 'Spain' },
  { id: '77', name: 'Sri Lanka' },
  { id: '10', name: 'Sweden' },
  { id: '7', name: 'Switzerland' },
  { id: '66', name: 'Tunisia' },
  { id: '86', name: 'Turkey' },
  { id: '47', name: 'Ukraine' },
  { id: '45', name: 'United Arab Emirates' },
  { id: '8', name: 'United Kingdom' },
  { id: '34', name: 'USA' },
  { id: '61', name: 'Vanuatu' },
  { id: '16', name: 'Vatikan' }
]
