import styled from 'styled-components'
import { theme, Link } from '@/client/components'
const { space, borders, color, font, breakpoint } = theme

interface IBackground {
  isOpen: boolean
}

export const Background = styled.div<IBackground>`
  display: ${(p) => (p.isOpen ? 'block' : 'none')};
  position: fixed;
  z-index: 1031;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.2);
  opacity: 1;
`

interface ISidebar {
  isOpen: boolean
}

export const Sidebar = styled.menu<ISidebar>`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  position: fixed;
  z-index: 9999;
  top: 0;
  left: 0;
  bottom: 0;
  width: 80vw;
  height: 100vh;
  overflow: hidden;
  overflow-y: auto;
  margin: 0;
  padding: ${space.xl};
  background-color: ${color.grayscale.white};
  transform: ${(p) => (p.isOpen ? 'translateX(0)' : 'translateX(-80vw)')};
  transition: all 0.27s ease;
`

export const NavSocialLink = styled.nav`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: ${space.lg};

  @media (min-width: ${breakpoint.lg}) {
    justify-content: flex-start;
  }
`

const getTypeColor = (type: string) => {
  if (type === 'facebook') return '#0062E0'
  if (type === 'instagram') return '#f50057'
  if (type === 'youtube') return '#FF0000'
  if (type === 'linkedin') return '#0077b5'
  return '#0062E0'
}

export const SocialLink = styled.a<{ type: string }>`
  display: block;
  color: ${color.grayscale.gray};
  transition: color 0.3s ease;

  &:not(:last-of-type) {
    margin-right: ${space.xl};

    @media (min-width: ${breakpoint.lg}) {
      margin-right: ${space.sm};
    }
  }

  &:hover {
    color: ${(p) => getTypeColor(p.type)};
    text-decoration: none;
  }
`

export const Options = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: start;
`

export const TitleCategory = styled.span`
  font-weight: bold;
  margin-top: ${space.md};
  margin-bottom: ${space.xs};
`

export const Close = styled.button`
  position: absolute;
  top: ${space.sm};
  right: ${space.sm};
  border-radius: 50%;
  border: 0;

  &:focus {
    border: ${borders.sizes.md} ${color.grayscale.white} solid;
  }
`

export const NavMenu = styled.nav`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin-bottom: ${space.xl};

  a:focus {
    outline: 0.125rem solid ${color.primary.pure};
  }
`

export const MenuLink = styled(Link)`
  clear: both;
  margin-bottom: ${space.xs};
  font-size: ${font.size.small};
`

export const MenuList = styled.nav`
  position: absolute;
  transition: all 2s;
  top: 100%;
  left: 0;
  display: none;
  flex-direction: column;
  flex-flow: column wrap;
  justify-content: space-around;
  padding: ${space.lg};
  gap: ${space.xs};
  max-height: 30rem;
  padding: ${space.lg};
  background-color: ${color.grayscale.lighter};
  box-shadow: 0px 9px 10px 0px rgba(16, 33, 51, 0.1);

  .subtitle {
    font-weight: ${font.weight.bold};

    &:not(:first-of-type) {
      margin-top: ${space.md};
    }
  }
`

export const BottomHeaderListItem = styled.li`
  position: relative;
  display: flex;
  align-items: center;
  padding: 0 ${space.xs};
  color: ${color.primary.darker};
  font-size: ${font.size.small};
  cursor: pointer;

  & > svg {
    display: none;
    margin-left: ${space.xs};

    @media (min-width: ${breakpoint.xl}) {
      display: block;
    }
  }

  &:nth-of-type(2),
  &:nth-of-type(3),
  &:nth-of-type(4),
  &:nth-of-type(5),
  &:nth-of-type(6) {
    display: none;
  }

  @media (min-width: ${breakpoint.md}) {
    &:nth-of-type(2),
    &:nth-of-type(3),
    &:nth-of-type(4) {
      display: flex;
    }
  }

  @media (min-width: ${breakpoint.lg}) {
    &:nth-of-type(5),
    &:nth-of-type(6) {
      display: flex;

      ${MenuList} {
        right: 0;
        left: unset;
      }
    }
  }

  @media (min-width: ${breakpoint.hd}) {
    font-size: ${font.size.root};
  }

  &:hover ${MenuList}, &:focus ${MenuList} {
    display: flex;
  }

  &:last-of-type ${MenuList} {
    right: 0;
    left: unset;
  }
`

export const BottomSidebar = styled.div`
  margin-top: 2rem;
`

export const LinkFooter = styled(Link)`
  font-size: ${font.size.small};
  line-height: 2rem;
`

export const LinkList = styled.nav`
  display: flex;
  flex-direction: column;
  margin-bottom: ${space.xl};
`
