export const footer = {
  infos: {
    material: {
      title: 'What material?',
      description:
        'Our free sample book offers you an overview of papers and finishes. Convince yourself of our quality.',
      button_label: 'Order now',
      image: {
        url: '/img/footer/material.png',
        alt: 'Material',
        width: 122,
        height: 122
      }
    },
    customer_service: {
      title: 'Customer Service',
      time: 'Monday to Friday, 8:00 a.m. to 6:00 p.m.',
      description: 'Our team will answer your questions quickly and competently and will be happy to advise you.',
      email: 'info@label.de',
      phone_number: '06502-984940',
      image: {
        url: '/img/footer/denise-lauterbach.png',
        alt: 'Denise Lauterbach',
        width: 213,
        height: 215
      }
    },
    our_customers: {
      title: 'Our customers',
      description:
        'One likes to show satisfied customers. Here you will find a small selection of our reference customers & partners.',
      button_label: 'Order now',
      image: {
        url: '/img/footer/our-customers.png',
        alt: 'Our customers',
        width: 122,
        height: 122
      }
    }
  },
  top_footer: [
    {
      title: 'Made in Germany',
      icon: 'place'
    },
    {
      title: 'Premium Finishes',
      icon: 'stars'
    },
    {
      title: 'Personalized & sustainable on request',
      icon: 'leaf'
    },
    {
      title: 'Numerous Spot Colors',
      icon: 'intersection'
    }
  ],
  footer_infos: {
    payment: {
      title: 'Payment options',
      credit_cards: [
        {
          name: 'Paypal',
          icon: 'paypal',
          width: 44,
          height: 44
        },
        {
          name: 'Visa',
          icon: 'visa',
          width: 34,
          height: 34
        },
        {
          name: 'Mastercard',
          icon: 'mastercard',
          width: 28,
          height: 28
        },
        {
          name: 'Klarna',
          icon: 'klarna',
          width: 46,
          height: 46
        },
        {
          name: 'Amex',
          icon: 'amex',
          width: 24,
          height: 24
        },
        {
          name: 'Rechnung',
          icon: 'rechnung',
          width: 44,
          height: 44
        },
        {
          name: 'Vorkasse',
          icon: 'vorkasse',
          width: 44,
          height: 44
        }
      ]
    },
    shop_safely: {
      title: 'Shop safely',
      stamps: [
        {
          image: {
            url: '/img/footer/shop-safely.png',
            alt: 'Shop safely',
            width: 184,
            height: 92
          }
        }
      ]
    },
    certified: {
      title: 'FSC certified',
      certifications: [
        {
          image: {
            url: '/img/footer/FSC-certified.png',
            alt: 'FC certified',
            width: 70,
            height: 106
          },
          description:
            'Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore.'
        }
      ]
    },
    awards: {
      title: 'Awards',
      list: [
        {
          image: {
            url: '/img/footer/hp.png',
            alt: 'HP',
            width: 161,
            height: 65
          }
        },
        {
          image: {
            url: '/img/footer/druck-medien.png',
            alt: 'FC certified',
            width: 161,
            height: 65
          }
        }
      ]
    }
  },
  bottom_footer: {
    left_side: {
      description:
        'Etiqueta.es is a B2B label printer. The offer is therefore aimed exclusively at companies and tradespeople',
      address: 'Etiket.de <br />Europa-Allee 21 <br />54343 Föhren / Germany',
      phone: '+49 6502 984 940',
      email: 'info@etikett.de',
      social_media: [
        {
          name: 'Facebook',
          type: 'facebook',
          link: 'https://www.facebook.com/etikett.de/'
        },
        {
          name: 'Instagram',
          type: 'instagram',
          link: 'https://www.instagram.com/etikett.de/'
        },
        {
          name: 'Youtube',
          type: 'youtube',
          link: 'https://www.youtube.com/user/etikettde'
        },
        {
          name: 'Linkedin',
          type: 'linkedin',
          link: 'https://www.linkedin.com/company/etikett-de-vertriebs-gmbh/'
        }
      ]
    },
    footer_links: [
      {
        title: 'Information',
        links: [
          {
            link: '/print-data-delivery',
            title: 'Data delivery',
            is_external_link: false
          },
          {
            link: '/order-process',
            title: 'Order process',
            is_external_link: false
          },
          {
            link: '/payment-methods',
            title: 'Payment Methods',
            is_external_link: false
          },
          {
            link: '/delivery-and-shipping-costs',
            title: 'Delivery',
            is_external_link: false
          },
          {
            link: '/conditions',
            title: 'Conditions',
            is_external_link: false
          },
          {
            link: '/customer-service-faq',
            title: 'FAQ',
            is_external_link: false
          },
          {
            link: '/privacy-policy',
            title: 'Privacy',
            is_external_link: false
          },
          {
            link: '/legal-warning',
            title: 'Legal Warning',
            is_external_link: false
          }
        ]
      },
      {
        title: 'Services',
        links: [
          {
            link: '/artwork-services',
            title: 'Print data workshop',
            is_external_link: false
          },
          {
            link: '/design-service',
            title: 'Design service',
            is_external_link: false
          },
          {
            link: '/artwork-assistant',
            title: 'Print template help',
            is_external_link: false
          },
          {
            link: '/tutorials',
            title: 'Tutorials',
            is_external_link: false
          },
          {
            link: '/print-data-center',
            title: 'Print Data Center',
            is_external_link: false
          },
          {
            link: '/test-print',
            title: 'Test print',
            is_external_link: false
          },
          {
            link: '/sample-book',
            title: 'Sample book',
            is_external_link: false
          },
          {
            link: '/express-production',
            title: 'Express production',
            is_external_link: false
          },
          {
            link: '/product/transparency',
            title: 'Transparency',
            is_external_link: false
          }
        ]
      },
      {
        title: 'Company',
        links: [
          {
            link: '/about-us',
            title: 'About us',
            is_external_link: false
          },
          {
            link: '/jobs',
            title: 'Career',
            is_external_link: false
          },
          {
            link: '/news',
            title: 'News',
            is_external_link: false
          },
          {
            link: '/press',
            title: 'Press',
            is_external_link: false
          },
          {
            link: '/contact',
            title: 'Contact',
            is_external_link: false
          },
          {
            link: '/blog',
            title: 'Blog',
            is_external_lins: true
          },
          {
            link: '/newsletter',
            title: 'Newsletter',
            is_external_lins: true
          }
        ]
      }
    ]
  }
}
