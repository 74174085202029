import { FC } from 'react'
import { Row, Col, Text, Button, Avatar, HorizontalScroll, Icon } from '@/client/components'
import { useRouterPush, useWindowSize } from '@/client/hooks'
import { IInfos, IInfoCards } from '@/client/types'
import * as S from './styles'

const InfoCards: FC<IInfoCards> = ({ material, customer_service, our_customers }) => {
  const { routerPush } = useRouterPush()

  return (
    <>
      <S.InfoCard>
        <S.InfoCardTop>
          <Text size='xlg' family='heading' weight='bold'>
            {material.title}
          </Text>
          <Text size='sm' color='weak'>
            {material.description}
          </Text>
        </S.InfoCardTop>

        <S.InfoCardBottom>
          <Button kind='warning' onClick={() => routerPush(material.button_link)}>
            {material.button_label}
          </Button>
          <Avatar
            size='xl'
            image={material.image.url}
            alt={material.image.alt}
            width={material.image.width}
            height={material.image.height}
            hasBorder
          />
        </S.InfoCardBottom>
      </S.InfoCard>

      <S.InfoCard>
        <S.InfoCardTop>
          <Text size='xlg' family='heading' weight='bold'>
            {customer_service.title}
          </Text>
          <Text size='sm' weight='medium'>
            {customer_service.time}
          </Text>
          <Text size='sm' color='weak'>
            {customer_service.description}
          </Text>
        </S.InfoCardTop>

        <S.InfoCardBottom>
          <div>
            <Text weight='medium' margin='0 0 .5rem'>
              <Icon name='email' size='s4' color='#005F7F' />
              {customer_service.email}
            </Text>

            <Text weight='medium'>
              <Icon name='phone' size='s4' color='#005F7F' />
              {customer_service.phone_number}
            </Text>
          </div>
          <Avatar
            size='xl'
            image={customer_service.image.url}
            alt={customer_service.image.alt}
            width={customer_service.image.width}
            height={customer_service.image.height}
            hasBorder
          />
        </S.InfoCardBottom>
      </S.InfoCard>

      <S.InfoCard>
        <S.InfoCardTop>
          <Text size='xlg' family='heading' weight='bold'>
            {our_customers.title}
          </Text>
          <Text size='sm' color='weak'>
            {our_customers.description}
          </Text>
        </S.InfoCardTop>
        <S.InfoCardBottom>
          <Button kind='warning' onClick={() => routerPush(our_customers.button_link)}>
            {our_customers.button_label}
          </Button>
          <Avatar
            size='xl'
            image={our_customers.image.url}
            alt={our_customers.image.alt}
            width={our_customers.image.width}
            height={our_customers.image.height}
            hasBorder
          />
        </S.InfoCardBottom>
      </S.InfoCard>
    </>
  )
}

export const Infos: FC<IInfos> = ({ infos: { material, customer_service, our_customers } }) => {
  const windowSize = useWindowSize()
  const isXlBreakpoint = windowSize?.width > 992

  return (
    <S.Infos>
      <Row>
        <Col xs={12}>
          {!isXlBreakpoint && (
            <HorizontalScroll>
              <S.InfoCards>
                <InfoCards material={material} customer_service={customer_service} our_customers={our_customers} />
              </S.InfoCards>
            </HorizontalScroll>
          )}
          {isXlBreakpoint && (
            <S.InfoCards>
              <InfoCards material={material} customer_service={customer_service} our_customers={our_customers} />
            </S.InfoCards>
          )}
        </Col>
      </Row>
    </S.Infos>
  )
}
