import { FC } from 'react'
import { Icon } from '@/client/components'
import { useRouterPush, useAuthUser } from '@/client/hooks'
import { ROUTER_PATH } from '@/client/utils'
import { t } from '@/infra/data'
import * as S from './styles'

const Login: FC = () => {
  const { isAuthenticated, user } = useAuthUser()
  const { defaultLocale, locale } = useRouterPush()

  return (
    <S.LoginAnchor href={isAuthenticated ? ROUTER_PATH.MY_ACCOUNT[defaultLocale] : ROUTER_PATH.LOGIN[defaultLocale]}>
      <Icon name='person' size='s5' />
      {isAuthenticated && (
        <S.Greet>
          {t.hello[locale]},
          <br />
          {user?.name}
        </S.Greet>
      )}
    </S.LoginAnchor>
  )
}

export default Login
